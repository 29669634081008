import { Helmet } from "react-helmet-async";

function MetaStuff() {
    return(
        <Helmet>
            <title>
                Q-Gate Manager
            </title>
        </Helmet>
    );
}

export default MetaStuff;