export const PART_ITEM_STATE = {
    NIO: {
        name: 'NIO',
        bgColor: '#F82C00',
        textColor: '#FFFFFF',
    },
    IO: {
        name: 'IO',
        bgColor: '#008800',
        textColor: '#FFFFFF',
    },
    UNCHECKED: {
        bgColor: '#FFFFFF',
        textColor: '#000000',
    }
};