import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Button, Container, Row } from 'react-bootstrap';
import { PART_ITEM_STATE } from 'utils/partItemState';

const ButtonsBlock = ({ items, onSelect }) => {
    return (
        <Container>
            <Row>
                <h6>
                    Katalog Fehlermerkmale / Fehlerorte
                </h6>
            </Row>
            <Row>
                {
                    items.map(({ id, status, fmname }, index) => (
                        <Button key={`bnt_${index}`} onClick={() => onSelect(id)}
                            variant="outline-secondary"
                            className='abstand'
                            style={{
                                backgroundColor: status ? PART_ITEM_STATE[status].bgColor : PART_ITEM_STATE.UNCHECKED.bgColor,
                                color: status ? PART_ITEM_STATE[status].textColor : PART_ITEM_STATE.UNCHECKED.textColor,
                            }}
                        > {fmname}</Button>
                    ))
                }
            </Row>
        </Container>
    );
}

export default ButtonsBlock;