import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Button, Col } from 'react-bootstrap';
import { loadParts } from 'service/partService';
import { useSelector } from 'react-redux';
import SearchField from './components/SearchField';
import PartImage from './components/PartImage';
import { PART_ITEM_TYPE } from 'utils/itemType';
import ButtonsBlock from './components/ButtonsBlock';
import Fehlermerkmale from './components/Fehlermerkmale';
import { PART_ITEM_STATE } from 'utils/partItemState';
import { BsCardChecklist } from "react-icons/bs";
import { COLORS } from 'utils/colors';
import FehlermerkmaleModal from './components/FehlermerkmaleModal';


const PartsPage = () => {
    const parts = useSelector((state) => state.part.parts);
    const [part, setPart] = useState();

    const [fehlermerkmaleModal, setFehlermerkmaleModal] = useState(false);

    const selectedId = part ? [part.id] : [];
    const setSelectedPart = (ids) => setPart(ids.length ? parts.find(p => p.id === ids[0]) : null);

    useEffect(() => {
        loadParts();
    }, []);

    function resetData() {
        setPart(null);
    }

    const updatePartItems = (id) => {
        if (part) {
            const newPart = { ...part };
            const items = [...newPart.items];
            const itemIndex = items.findIndex(item => item.id === id);
            if (itemIndex !== -1) {
                const item = items[itemIndex];
                item.status = !item.status ? PART_ITEM_STATE.NIO.name : item.status === PART_ITEM_STATE.NIO.name ? PART_ITEM_STATE.IO.name : null;
                items[itemIndex] = item;
            }
            newPart.items = items;
            setPart(newPart);
        }
    }

    const itemsWithStatus = part ? part.items.filter(item => !!item.status) : [];
    const canSend = part && part.items.length === itemsWithStatus.length;

    return (
        <Container>
            <div className='d-flex justify-content-between w-100'>
                <Col xs lg="3">
                    <SearchField
                        selectedId={selectedId}
                        options={parts.map(part => part.id)}
                        disabled={part}
                        onChange={setSelectedPart}
                        onReset={resetData}
                    />
                </Col>
                {!!part && <div className='d-block d-md-none' onClick={() => setFehlermerkmaleModal(true)}>
                    <BsCardChecklist size={28} color={COLORS.primary} />
                </div>}
            </div>

            {!!part && <><Row className='partPage'>
                <Col md='7' lg='5'>
                    <PartImage
                        imageUrl={part.image}
                        name={part.name}
                        areas={part.items.filter(item => item.type === PART_ITEM_TYPE.area)}
                        onSelect={updatePartItems}
                    />
                </Col>
                <Col md='5' lg='3'>
                    <ButtonsBlock items={part.items} onSelect={updatePartItems} />
                </Col>
                <Col lg='4' className='d-none d-md-block'>
                    <Fehlermerkmale items={part.items.filter(item => !!item.status)} />
                    <Row>
                        <Col>
                            <Button style={{ minWidth: '100%', width: '100%' }} disabled={!canSend}>Senden</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
                <FehlermerkmaleModal show={fehlermerkmaleModal} handleClose={() => setFehlermerkmaleModal(false)}>
                    <Fehlermerkmale items={part.items.filter(item => !!item.status)} />
                    <Row>
                        <Col>
                            <Button style={{ minWidth: '100%', width: '100%' }} disabled={!canSend}>Senden</Button>
                        </Col>
                    </Row>
                </FehlermerkmaleModal>
            </>
            }
        </Container>
    );
}

export default PartsPage;