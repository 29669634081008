import './app.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import DashboardLayout from './layouts/dashboard/DashboardLayout';

const App = () => {
    return (
        <DashboardLayout />
    );
}

export default App;