import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from 'logo.svg';
import PartsPage from './pages/parts/PartsPage';
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from "react-icons/ai";
import { COLORS } from 'utils/colors';

const DashboardLayout = () => {

    const [fullScreen, setFullScreen] = useState(false);

    const switchFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            setFullScreen(false);
        } else {
            document.querySelector('.main-container').requestFullscreen();
            setFullScreen(true);
        }
    }


    return (
        <Container fluid className='main-container'>
            <Row>
                <Navbar id="NavBarMain" className="shadow p-3 mb-5 bg-white rounded">
                    <Container>
                        <div className='d-flex justify-content-between w-100'>
                            <Navbar.Brand href="#home">
                                <img
                                    alt="logo"
                                    src={logo}
                                    width="30"
                                    height="30"
                                />
                                Q-Gate Manager
                            </Navbar.Brand>
                            <div>
                                <div
                                    onClick={switchFullScreen}>
                                    {fullScreen ? 
                                    <AiOutlineFullscreenExit size={24} color={COLORS.primary}/> : 
                                    <AiOutlineFullscreen size={26} color={COLORS.primary}/>}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </Row>
            <Row>
                <PartsPage />
            </Row>
        </Container>
    );
}

export default DashboardLayout;