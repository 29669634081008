import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Button, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

const SearchField = ({ selectedId, disabled = false, onChange, options, onReset }) => {
    return (
        <InputGroup
            className="mb-3" >
            <InputGroup.Text id="basic-addon1">Id</InputGroup.Text>
            <Typeahead
                id="basic-typeahead-single"
                labelKey="id"
                options={options}
                placeholder="Choose a part by id..."
                selected={selectedId}
                disabled={disabled}
                onChange={onChange}
            />
            <Button variant="outline-secondary" onClick={onReset}>
                Reset
            </Button>
        </InputGroup>
    );
}

export default SearchField;