import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Col, Container, Row } from 'react-bootstrap';
import { PART_ITEM_STATE } from 'utils/partItemState';

const Fehlermerkmale = ({ items }) => {
    return (
        <Col>
            <h6>Fehlermerkmale</h6>
            <Container>
                {items.map(({ fmname, status }, index) => (
                    <Row key={`fehlermerkmale_item_${index}`}>
                        <Col xs lg="3" className='warenkorbitem' style={{
                            backgroundColor: status ? PART_ITEM_STATE[status].bgColor : PART_ITEM_STATE.UNCHECKED.bgColor,
                            color: status ? PART_ITEM_STATE[status].textColor : PART_ITEM_STATE.UNCHECKED.textColor,
                        }}>{status}</Col><Col className='warenkorbitem'>{fmname}</Col>
                    </Row>
                ))}
            </Container>
        </Col>
    );
}

export default Fehlermerkmale;