import { partActionType } from "./partActionType";

const initState = {
  parts: [],
};

export default function partReducer(state = initState, action) {
  switch (action.type) {
    case  partActionType.setParts: {
      const { parts } = action.payload;
      return { ...state, parts };
    }
    default:
      return state;
  }
}
