import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Container } from 'react-bootstrap';
import Konva from 'konva';
import { Rect } from 'konva/lib/shapes/Rect';
import { PART_ITEM_STATE } from 'utils/partItemState';

const PartImage = ({ name, imageUrl, areas, onSelect }) => {

    const areaStyle = {
        stroke: "#FA0C00",
        strokeWidth: 2,
    };

    useEffect(() => {

        const renderImage = () => {
            const image = new Image();
            image.onload = function () {
                const container = document.querySelector('#stage');
                const width = container.offsetWidth;
                const height = (width * image.height) / image.width;
                const widthCoeff = width / image.width;
                const heightCoeff = height / image.height;
    
                const stage = new Konva.Stage({
                    container: 'stage',
                    width,
                    height,
                });
    
                const bgImg = new Konva.Image({
                    id: 'main',
                    image,
                    width,
                    height,
                });
    
                const layer = new Konva.Layer({ id: 'mainLayer' });
                stage.add(layer);
                layer.add(bgImg);
                layer.draw();
                const drawLayer = new Konva.Layer({ id: 'drawLayer' });
                stage.add(drawLayer);
                layer.draw();
                areas?.length
                    && drawAreas(drawLayer, widthCoeff, heightCoeff);
            };
            image.src = imageUrl;
        }

        !!imageUrl && renderImage();
    }, [imageUrl, areas]);

    const drawAreas = (drawLayer, widthCoeff, heightCoeff) => {
        areas.forEach(({ id, x, y, width, height, fmname, status }) => {
            const shapeX = x * widthCoeff;
            const shapeY = y * heightCoeff;
            const shapeWidth = width * widthCoeff;
            const shapeHeight = height * heightCoeff;

            const shapeOptions = {
                x: shapeX,
                y: shapeY,
                width: shapeWidth,
                height: shapeHeight,
                stroke: areaStyle.stroke,
                strokeWidth: areaStyle.strokeWidth,
            };

            const rect = new Rect(shapeOptions);
            rect.on('click', function () {
                onSelect(id);
            });

            rect.on('touchstart', function () {
                onSelect(id);
            });

            drawLayer.add(rect);
            drawLabel(
                drawLayer,
                shapeX,
                shapeY + shapeHeight + 4,
                fmname,
                status ? PART_ITEM_STATE[status].bgColor : PART_ITEM_STATE.UNCHECKED.bgColor,
                status ? PART_ITEM_STATE[status].textColor : PART_ITEM_STATE.UNCHECKED.textColor,
            );
        });
    }

    const drawLabel = (layer, x, y, text, bgColor, textColor) => {
        const label = new Konva.Label({
            x,
            y,
            opacity: 1,
        });

        label.add(
            new Konva.Tag({
                fill: bgColor,
                stroke: '#000000',
                strokeWidth: 1,
            })
        );

        label.add(
            new Konva.Text({
                text: text,
                fontSize: 12,
                padding: 5,
                fill: textColor,
            })
        );

        layer.add(label);
    };

    return (
        <Container fluid>
            <h3>{name}</h3>
            <div
                id="stage"
                className="tw-min-w-min tw-pt-16 tw-flex tw-items-center tw-justify-center"
            ></div>
        </Container>
    );
}

export default PartImage;